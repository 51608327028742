
import dynamic from 'next/dynamic';
import { serverLocationDetector } from '../utils/locationDetector';
const Layout = dynamic(() => import('../components/_App/Layout'))
const Login = dynamic(() => import('../templates/Login'))

const LoginPage = (props) => {
    return (
        <Layout
            isAppleMobile = {props.isAppleMobile}
            seo={{
                title:`Login | Weedzly`,
                url:'https://weedzly.com/login',
                description: `Welcome back! Please log in to use your Weedzly account. If you're a new user, feel free to create an account.`
            }}
        >
            <Login />
        </Layout>
    )
}

export default LoginPage

export async function getServerSideProps(context){
    let isAppleMobile = (context.req
        ? context.req.headers['user-agent']
        : navigator.userAgent).match(
          /iPhone|iPad|iPod/i
        )
    await serverLocationDetector(context)
    return {
        props: {isAppleMobile}
    } 
}